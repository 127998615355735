<template>
  <b-modal
    title="Bitacora de Facturador"
    id="ModalBitacoraFacturador"
    header-class="d-block"
    size="custom-lg-max"
    hide-footer
  >
    <template #modal-header>
      <div class="row">
        <div class="col-sm-3 pr-0">
          <h5 class="modal-title">
            <b>Bitacora de Facturador</b>
          </h5>
        </div>
        <div class="col-sm-9">
          <button class="btn btn-orange text-white py-1">De Hoy</button>
        </div>
      </div>
    </template>
    <div class="row mb-3">
      <div class="col-sm-12">
        <b-table
          :fields="fieldsTable"
          :items="listaTable"
        >
          <template #cell(importe)="data">
            {{data.item.importe | numeral('0,0.00')}}
          </template>
          <template #cell(estado)="data">
            <span v-if="data.item.estado === 1" class="text-danger">Pendiente de Envio</span>
            <span v-if="data.item.estado === 2" class="text-success">Enviado con VP: 23/24 EL: 33</span>
          </template>
        </b-table>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 text-right">
        <button class="btn btn-success">
          Envio Manual
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalBitacoraFacturador',
  data() {
    return {
      fieldsTable: [
        {
          key: 'numero', label: 'N°', class: 'text-center', thClass: 'bg-orange', thStyle: { width: '5%' },
        },
        {
          key: 'fecha', label: 'Fecha Hora', class: 'text-center', thClass: 'bg-orange', thStyle: { width: '18%' },
        },
        {
          key: 'origen', label: 'Origen', class: 'text-center', thClass: 'bg-orange', thStyle: { width: '10%' },
        },
        {
          key: 'numero_factura', label: 'N° Factura', class: 'text-center', thClass: 'bg-orange', thStyle: { width: '10%' },
        },
        {
          key: 'importe', label: 'Importe', class: 'text-center', thClass: 'bg-orange', thStyle: { width: '9%' },
        },
        {
          key: 'usuario', label: 'Usuario', class: 'text-center', thClass: 'bg-orange', thStyle: { width: '26%' },
        },
        {
          key: 'estado', label: 'Estado', class: 'text-center', thClass: 'bg-orange', thStyle: { width: '22%' },
        },
      ],
      listaTable: [
        {
          id: 2,
          numero: '2',
          fecha: '12/01/2024 (11:22:01)',
          origen: 'VP: 23/24',
          numero_factura: 'EL: 34',
          importe: '3000',
          usuario: 'Jorge',
          estado: 1,
        },
        {
          id: 1,
          numero: '1',
          fecha: '12/01/2024 (11:23:41)',
          origen: 'VP: 3/24',
          numero_factura: 'EL: 10',
          importe: '200',
          usuario: 'Jorge',
          estado: 2,
        },
      ],
    };
  },
};
</script>
