import axios from '@/modules/common/axiosERP';

import { util } from '@/plugins/util';

function openNewWindowWithPDF(response) {
  const blob = new Blob([response.data], { type: 'application/pdf' });
  const downloadUrl = URL.createObjectURL(blob);
  window.open(downloadUrl, 'Reporte');
}

function downloadPDF(url, sinLogo, sinImporte, tratamientoSinDetalle, tipoFacturaId) {
  const params = {
    origin: 2, // 1 lo pide erp, 2 lo pide clinica
    sin_logo: sinLogo,
    sin_importe: sinImporte,
    tratamiento_sin_detalle: tratamientoSinDetalle,
    tipo_factura_id: tipoFacturaId,
  };
  axios.get(url, { params, responseType: 'blob' })
    .then((response) => {
      openNewWindowWithPDF(response);
    }).catch(() => {
      util.showNotify('Hubo un problema al obtener el PDF', 'error');
    });
}
export async function getPDF(url, sinLogo, sinImporte, tratamientoSinDetalle, tipoFacturaId) {
  const params = {
    sin_logo: sinLogo,
    sin_importe: sinImporte,
    tratamiento_sin_detalle: tratamientoSinDetalle,
    tipo_factura_id: tipoFacturaId,
  };
  let pdf = null;
  try {
    const RESPONSE = await axios.get(url, { params, responseType: 'blob' });
    pdf = RESPONSE;
  } catch (error) {
    util.showNotify('Hubo un problema al obtener el PDF', 'error');
  }
  return pdf;
}

export default downloadPDF;
